import React from "react";
import Navbar from "../components/navbar";
import HeroSection from "../components/heroSection";
import Skills from "../components/SkillCards";
import Projects from "./Projects";
import Contact from "../components/ContactSection";
// import CustomerReview from "../components/CustomerReview";
import ProgressBar from "../components/ProgressBar";
import Footer from "../components/Footer";
import FloatingMenu from "../components/FloatingMenu";

const Home = () => {
	return (
		<>
			<div id="land">
				<ProgressBar />
				<FloatingMenu />
				<Navbar />
				<HeroSection />
				<Skills />
				{/* <Education /> */}
				<Projects />
				{/* <CustomerReview /> */}
				<Contact />
				<Footer />
			</div>
		</>
	);
};

export default Home;
