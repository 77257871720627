import React from "react";
import { Link } from "react-router-dom";
import Typewriter from "typewriter-effect";
import "../assets/styles/heroSection.css";
import "../index.css";

const heroSection = () => {
	return (
		<section id="title">
			<div>
				<div>
					<div className="headlineDiv tracking-in-expand">
						<h1 className="headline">
							Hi There!!! <br />
							I'm{" "}
							<Link
								id="aboutMeLink"
								to="/about">
								S&nbsp;&nbsp;&nbsp;urabh_
							</Link>{" "}
						</h1>
						<h2 id="typewriter">
							<Typewriter
								options={{
									autoStart: true,
									loop: true,
									delay: 40,
									strings: [
										"a Full Stack Web Developer",
										"a MERN Developer",
										"Let's Start a Project!",
									],
								}}
							/>
						</h2>
					</div>
					<div className="buttonSection">
						<div className="contactBtn">
							<a
								className="mailto btn heroBtn btn-lg btn-outline-info"
								href="#contactSection">
								Start a Project
							</a>
						</div>
					</div>
				</div>
			</div>
		</section>
	);
};

export default heroSection;
