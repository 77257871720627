import React, { useState, useEffect } from "react";
import ProjectCard from "../components/ProjectCard";
import KalaniGroup from "../assets/images/projects/kalani-home-page.png";
import NHRSJO from "../assets/images/projects/nhrsjo.png";
import Deltinroyals from "../assets/images/projects/deltinroyals.png";
import "../assets/styles/projects.css";

const Projects = () => {
	const projects = [
		{
			image: NHRSJO,
			title: "NHRSJO",
			description: "NHRSJO non-profit national human rights organization.",
			link: "https://nhrsjo.in/index.html",
		},
		{
			image: KalaniGroup,
			title: "Kalani Group Portfolio",
			description: "One stop solution for all end-to-end logistic needs.",
			link: "https://kalanigroup.in/",
		},
		{
			image: Deltinroyals,	
			title: "Deltin Royals",
			description: "The Ultimate Betting Playground.",
			link: "https://deltinroyals.com/",
		},
	];

	const [currentIndex, setCurrentIndex] = useState(0);
	const [isDragging, setIsDragging] = useState(false);
	const [startX, setStartX] = useState(0);
	const [currentX, setCurrentX] = useState(0);

	useEffect(() => {
		const interval = setInterval(() => {
			if (!isDragging) {
				setCurrentIndex((prevIndex) => (prevIndex === projects.length - 1 ? 0 : prevIndex + 1));
			}
		}, 7000);

		return () => clearInterval(interval);
	}, [isDragging, projects.length]);

	const prevSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex === 0 ? projects.length - 1 : prevIndex - 1));
	};

	const nextSlide = () => {
		setCurrentIndex((prevIndex) => (prevIndex === projects.length - 1 ? 0 : prevIndex + 1));
	};

	// Mouse drag handlers
	const handleMouseDown = (e) => {
		setIsDragging(true);
		setStartX(e.clientX);
	};

	const handleMouseMove = (e) => {
		if (!isDragging) return;
		setCurrentX(e.clientX);
	};

	const handleMouseUp = () => {
		if (!isDragging) return;

		const deltaX = startX - currentX;

		if (deltaX > 50) {
			nextSlide();
		} else if (deltaX < -50) {
			prevSlide();
		}

		setIsDragging(false);
	};

	// Touch drag handlers
	const handleTouchStart = (e) => {
		setIsDragging(true);
		setStartX(e.touches[0].clientX);
	};

	const handleTouchMove = (e) => {
		if (!isDragging) return;
		setCurrentX(e.touches[0].clientX);
	};

	const handleTouchEnd = () => {
		if (!isDragging) return;

		const deltaX = startX - currentX;

		if (deltaX > 50) {
			nextSlide();
		} else if (deltaX < -50) {
			prevSlide();
		}

		setIsDragging(false);
	};

	return (
		<>
			<div id="ProjectSection">
				<div>
					<h2 className="text-3xl text-white text-center mb-2">Projects</h2>
				</div>
				<div
					className="carousel slide"
					onMouseDown={handleMouseDown}
					onMouseMove={handleMouseMove}
					onMouseUp={handleMouseUp}
					onMouseLeave={() => setIsDragging(false)}
					onTouchStart={handleTouchStart}
					onTouchMove={handleTouchMove}
					onTouchEnd={handleTouchEnd}
					data-bs-ride="carousel"
					data-bs-touch="true"
				>
					<div className="carousel-inner">
						{projects.map((project, index) => (
							<div
								className={`carousel-item ${index === currentIndex ? "active" : ""}`}
								key={index}
							>
								<ProjectCard
									image={project.image}
									title={project.title}
									description={project.description}
									link={project.link}
								/>
							</div>
						))}
					</div>
				</div>
			</div>
		</>
	);
};

export default Projects;
