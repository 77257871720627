import React, { useState, useRef, useEffect, useCallback } from "react";
import "../assets/styles/floting_menu.css";
import Github from "../assets/images/github.webp";
import Linkedin from "../assets/images/linkedin-logo.png";
import Gmail from "../assets/images/gmail-logo.webp";
import { Link } from "react-router-dom";

const FloatingMenu = () => {
	const isMobile = window.innerWidth <= 768;
	const defaultPosition = isMobile ? { x: 162, y: 225 } : { x: 696, y: 204 };

	const [position, setPosition] = useState(defaultPosition);
	const [dragging, setDragging] = useState(false);
	const [menuOpen, setMenuOpen] = useState(false);
	const menuRef = useRef(null);

	const handleDragStart = (e) => {
		e.preventDefault();
		setDragging(true);

		if (e.type === "touchstart") {
			document.body.style.overflow = "hidden";
		}
	};

	const handleDrag = useCallback(
		(e) => {
			if (!dragging) return;

			const clientX = e.type.includes("mouse") ? e.clientX : e.touches[0].clientX;
			const clientY = e.type.includes("mouse") ? e.clientY : e.touches[0].clientY;

			const menuWidth = menuRef.current.offsetWidth;
			const menuHeight = menuRef.current.offsetHeight;

			const viewportWidth = window.innerWidth;
			const viewportHeight = window.innerHeight;

			const x = Math.min(Math.max(0, clientX - menuWidth / 2), viewportWidth - menuWidth);
			const y = Math.min(Math.max(0, clientY - menuHeight / 2), viewportHeight - menuHeight);

			setPosition({ x, y });
		},
		[dragging] // Only recreate if `dragging` changes
	);

	const handleDragEnd = () => {
		setDragging(false);
		document.body.style.overflow = "auto";
	};

	useEffect(() => {
		if (dragging) {
			window.addEventListener("mousemove", handleDrag);
			window.addEventListener("touchmove", handleDrag);
			window.addEventListener("mouseup", handleDragEnd);
			window.addEventListener("touchend", handleDragEnd);
		}

		return () => {
			window.removeEventListener("mousemove", handleDrag);
			window.removeEventListener("touchmove", handleDrag);
			window.removeEventListener("mouseup", handleDragEnd);
			window.removeEventListener("touchend", handleDragEnd);
		};
	}, [dragging, handleDrag]); // Include `handleDrag` here

	const toggleMenu = (e) => {
		e.stopPropagation();
		if (!dragging) {
			setMenuOpen((prev) => !prev);
		}
	};

	return (
		<div
			ref={menuRef}
			className="floating-menu"
			style={{
				top: `${position.y}px`,
				left: `${position.x}px`,
				position: "fixed",
			}}
			onMouseDown={handleDragStart}
			onTouchStart={handleDragStart}
		>
			<button
				className="menu-btn"
				onClick={toggleMenu}
			>
				{menuOpen ? "×" : "+"}
			</button>
			<div className={`menu-items ${menuOpen ? "active" : ""}`}>
				<button className="menu-item">
					<Link to="https://github.com/sourabhongit?tab=repositories">
						<img
							src={Github}
							alt="github"
						/>
					</Link>
				</button>
				<button className="menu-item">
					<Link to="https://www.linkedin.com/in/heymrsourabh/">
						<img
							src={Linkedin}
							alt="linkedin"
						/>
					</Link>
				</button>
				<button className="menu-item">
					<Link to="mailto:get.sourabhdas@gmail.com">
						<img
							src={Gmail}
							alt="gmail"
						/>
					</Link>
				</button>
				<button className="menu-item">
					<Link
						to="/text-format"
						style={{ fontSize: "2.5rem" }}
					>
						⚙️
					</Link>
				</button>
			</div>
		</div>
	);
};

export default FloatingMenu;
